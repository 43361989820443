/* global google */
import ReactDOMServer from 'react-dom/server';
import React from "react";

import { Loader } from "@googlemaps/js-api-loader"

import "./InteractiveMap.css";


let data = [
  {
    position: { lat: 48.856718722994216, lng: 2.3515995644585668 },
    name: "Marche pour le climat",
    date: "15 Août 2021",
    description: "On se retrouve ensemble pour marcher pour le climat !"
  },
  {
    position: { lat: 48.88, lng: 2.32 },
    name: "Manifestation pour la taxe carbone",
    date: "10 Septembre 2021",
    description: "Manifestons pour la mise en place de la taxe carbone."
  }
]

export class InteractiveMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data
    }
  }

  componentDidMount() {
    this.initMap()
  }

  returnInfoWindow(item) {
    const contentString = 
      <div id="content">
        <h1 id="firstHeading" className="firstHeading">{item.name}</h1>
        <div id="bodyContent">
          <p>{item.description}</p>
          <p>{item.date}</p>
        </div>
      </div>
      return ReactDOMServer.renderToStaticMarkup(contentString)
  }

  initMap() {
    const loader = new Loader({
      apiKey: "AIzaSyAXuswqsjfSBn8iGRNo-lOsMQKHMgZ9bRM",
      version: "weekly",
    });
    loader.load().then(() => {
      const map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 48.86068390858000, lng: 2.344300656199954 },
        zoom: 13,
        mapID: "a47b4cc58a6e5b87",
        disableDefaultUI: true,
        zoomControl: true,
        mapTypeControlOptions: {
          mapTypeIds: ["roadmap", "satellite", "hybrid", "terrain", "styled_map"],
        },
      });
      const styledMapType = new google.maps.StyledMapType([
        {
          "featureType": "administrative",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels",
          "stylers": [
            {
              "color": "#919191"
            },
            {
              "lightness": 20
            },
            {
              "visibility": "on"
            },
            {
              "weight": 0.5
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text",
          "stylers": [
            {
              "weight": 0.5
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#141414"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }
      ]);
      map.mapTypes.set("styled_map", styledMapType);
      map.setMapTypeId("styled_map")
      this.state.data.map((item) => {
        const marker = new google.maps.Marker({
          position: item.position,
          map,
          title: item.name,
        });
        const infowindow = new google.maps.InfoWindow({
          content: this.returnInfoWindow(item),
        });
        marker.addListener("click", () => {
          infowindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          });
        });
        return null
      })
    });
  }

  render() {
    return (
      <div className="content interactiveMap">
        <div id="map">

        </div>
      </div>
    );
  }
}
