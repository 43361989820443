import React, { useContext } from 'react'
import parse from 'html-react-parser'

import { LanguageContext } from './LanguageContext'

import frData from '../languages/fr.json'

export const languageOptions = {
  fr: 'Français',
  en: 'English',
  es: "Espagnol",
  po: "Portugais"
};


export class LanguageProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      defaultLanguage: window.localStorage.getItem('rcml-lang'),
      userLanguage: window.localStorage.getItem('rcml-lang'),
      provider: {
        userLanguage: null,
        dictionary: null,
        userLanguageChange: null
      }
    }
    this.userLanguageChange.bind = this.userLanguageChange
  }

  userLanguageChange(selected) {
    const newLanguage = languageOptions[selected] ? selected : 'fr'
    window.localStorage.setItem('rcml-lang', newLanguage);
  }

  fetchShoppingItems = async () => {
    try {
      //const res = await fetch("https://api.planetearthnow.org/api/singletons/get/content?token=47185fbae81af2f0f152a6ae6aa036");
      //return (res.json())
      return frData
    } catch {
      return (null)
    }
  }

  componentDidMount(){
    this.fetchShoppingItems().then(result => {
      this.setState({
        provider: {
          userLanguage: this.state.userLanguage,
          //dictionary: result.planetearthnow,
          dictionary: result,
          userLanguageChange: this.userLanguageChange
        }
        
      })
      //console.log(this.state.data)
    }).catch((error) => {
      console.error('Error:', error);
      return (null)
    });
  }

  render() {
    return (
      <LanguageContext.Provider value={this.state.provider}>
        {this.props.children}
      </LanguageContext.Provider>
    )
  }
}

export function Text({ tid }) {
  try {
    const languageContext = useContext(LanguageContext)
    const path = tid.split('.')
    const test = path.reduce((o, n) => o[n], languageContext.dictionary)
    if (test === null) return null
    if (typeof(test) === "string") return parse(test || tid)
    return (test)
  }
  catch {
    return null
  }
};




