import React from "react";

import { Quote } from "../../components/Quote/Quote.js";

import "./HeroHeader.css";

import educationImage from '../Focus/education.jpg';
import actionImage from '../Focus/action.jpg';
import mobilisationImage from '../Focus/mobilisation.jpeg';

let image = {
  "education": educationImage,
  "action": actionImage,
  "mobilisation": mobilisationImage
}

export class HeroHeader extends React.Component {
  render() {
    return (
      <div className="heroheader">
        <figure>
          <img src={image[this.props.path]} alt="Education"/>
        </figure>
        <div className="wrapper-heroheader">
          <div className="wrapper-heroheader-info">
            <h1>{this.props.title}</h1>
            {this.props.introduction ? <h2>{this.props.introduction}</h2> : null}
          </div>
          {this.props.quote ? <Quote quote={this.props.quote} author={this.props.author} /> : null}
        </div>
      </div>
    );
  }
}