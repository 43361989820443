import React from "react";
import "./ModuleIntroduction.css";


export class ModuleIntroduction extends React.Component {
  render() {
    return (
      <h3>{this.props.text}</h3>
    );
  }
}