import React from "react";

import "./CitizenSuggestion.css";

export class CitizenSuggestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="content citizenSuggestion">
        <p>Propositions citoyennes</p>
      </div>
    );
  }
}
