import React from "react";

import { Header } from "../scenes/Header/Header.js"
import { HeroHeader } from "../scenes/HeroHeader/HeroHeader.js"
import { Module } from "../components/Module/Module.js"

import { Partners } from "../scenes/Partners/Partners.js"
import { Counter } from "../scenes/Counter/Counter.js"
import { CitizenSuggestion } from "../scenes/CitizenSuggestion/CitizenSuggestion.js"

import { Footer } from "../scenes/Footer/Footer.js"


import { Text } from '../containers/Language';


import "./pageStyle.css";


export class Mobilisation extends React.Component {
  render() {
    return (
      <div className="page mobilisation">

        <Header />

        <HeroHeader 
          title={<Text tid="mobilisation.header.title" />}
          introduction={<Text tid="mobilisation.header.introduction" />}
          quote={<Text tid="mobilisation.header.quote" />}
          author={<Text tid="mobilisation.header.author" />}
          path="mobilisation"
        />

        <Module
          moduleTitle={<Text tid="mobilisation.partners.title" />}
          displaySuggestions={false}
          bodyIntroductionText={<Text tid="mobilisation.partners.introduction" />}
          bodyContent={<Partners />}
        />

        <Module
          moduleTitle={<Text tid="mobilisation.counter.title" />}
          displaySuggestions={true}
          bodyIntroductionText={null}
          bodyContent={<Counter />}
        />

        <Module
          moduleTitle={<Text tid="mobilisation.propositions.title" />}
          displaySuggestions={true}
          bodyIntroductionText={<Text tid="mobilisation.propositions.introduction" />}
          bodyContent={<CitizenSuggestion />}
        />

        <Footer />
      </div>
    );
  }
}