/* global google */
import React from "react";

import { Loader } from "@googlemaps/js-api-loader"

import "./AddressMap.css";

export class AddressMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this.initMap()
  }

  initMap() {
    const loader = new Loader({
      apiKey: "AIzaSyAXuswqsjfSBn8iGRNo-lOsMQKHMgZ9bRM",
      version: "weekly",
    });
    loader.load().then(() => {
      const map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 48.85853593011724, lng: 2.3218298403093582 },
        zoom: 14,
        disableDefaultUI: true,
        zoomControl: true,
      });
      new google.maps.Marker({
        position: { lat: 48.85853593011724, lng: 2.3218298403093582 },
        map,
        title: "On se trouve ici !",
      });
    });
  }

  render() {
    return (
      <div className="content addressMap">
        <div className="addressMap-left">
          <div id="map">

          </div>
        </div>
        <div className="addressMap-right">
          <div className="information">
            <h3>Adresse</h3>
            <h4>Rue Saint-Dominique, 75007 Paris</h4>
            <h3>Mail</h3>
            <h4>contact@planetearthnow.org</h4>
            <h3>Téléphone</h3>
            <h4>00.11.22.33.44</h4>
          </div>
        </div>
      </div>
    );
  }
}
