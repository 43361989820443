import React from "react";

import { SideBanner } from "../SideBanner/SideBanner.js"


import "./ModuleHeader.css";


export class ModuleHeader extends React.Component {
  render() {
    return (
      <header>
        <h2>{this.props.title}</h2>
        {this.props.suggestions ? <SideBanner 
          title={this.props.title}
        /> : null}
      </header>
    );
  }
}