import React from "react";

import { Text } from '../../containers/Language';

import "./Feed.css";


export class Feed extends React.Component {
  render() {
    return (
      <div className="feed">
        <div className="content">
          <h2><Text tid="home.feed.name" /></h2>
        </div>
      </div>
    );
  }
}