import React from "react";


import { Text } from '../containers/Language';


import { Header } from "../scenes/Header/Header.js"
import { HeroHeader } from "../scenes/HeroHeader/HeroHeader.js"
import { Module } from "../components/Module/Module.js"

import { IndividualActs } from "../scenes/IndividualActs/IndividualActs.js"
import { InteractiveMap } from "../scenes/InteractiveMap/InteractiveMap.js"

import { Footer } from "../scenes/Footer/Footer.js"


import { List } from "../scenes/List/List.js"


import "./pageStyle.css";


export class Action extends React.Component {
  render() {
    return (
      <div className="page action">
        <Header />

        <HeroHeader 
          title={<Text tid="action.header.title" />}
          introduction={<Text tid="action.header.introduction" />}
          quote={<Text tid="action.header.quote" />}
          author={<Text tid="action.header.author" />}
          path="action"
        />
        
        <Module
          moduleTitle={<Text tid="action.individualActs.title" />}
          displaySuggestions={true}
          bodyIntroductionText={<Text tid="action.individualActs.introduction" />}
          bodyContent={<IndividualActs />}
        />

        <Module
          moduleTitle={<Text tid="action.collectiveActs.title" />}
          displaySuggestions={true}
          bodyIntroductionText={<Text tid="action.collectiveActs.introduction" />}
          bodyContent={<List />}
        />

        <Module
          moduleTitle={<Text tid="action.join.title" />}
          displaySuggestions={true}
          bodyIntroductionText={<Text tid="action.join.introduction" />}
          bodyContent={<InteractiveMap />}
        />

        {/* <Module
          moduleTitle="Agir sur les grands enjeux"
          displaySuggestions={true}
          bodyIntroductionText="Vous souhaitez agir sur les grands chantiers de la défense de l’environnement ?"
          bodyContent={<CollectiveActs />}
        /> */}

        <Footer />
      </div>
    );
  }
}