import React from "react";


import { Text } from '../containers/Language';


import { Header } from "../scenes/Header/Header.js"
import { Module } from "../components/Module/Module.js"

import { AboutUs } from "../scenes/AboutUs/AboutUs.js"
import { Projects } from "../scenes/Projects/Projects.js"
import { SupportUs } from "../scenes/SupportUs/SupportUs.js"

import { Footer } from "../scenes/Footer/Footer.js"


import "./pageStyle.css";


export class About extends React.Component {
  render() {
    return (
      <div className="page about">
        <Header />
        
        <Module
          id="abous_us"
          moduleTitle={<Text tid="association.aboutUs.title" />}
          displaySuggestions={false}
          bodyIntroductionText={null}
          bodyContent={<AboutUs/>}
        />

        <Module
          id="projects"
          moduleTitle={<Text tid="association.projects.title" />}
          displaySuggestions={false}
          bodyIntroductionText={<Text tid="association.projects.introduction" />}
          bodyContent={<Projects/>}
        />

        <Module
          id="support_us"
          moduleTitle={<Text tid="association.supportUs.title" />}
          displaySuggestions={false}
          bodyIntroductionText={<Text tid="association.supportUs.introduction" />}
          bodyContent={<SupportUs/>}
        />

        <Footer />
      </div>
    )
  }
}