import React from "react";


import { Link } from "react-router-dom";


import "./SocialNetworks.css";


import facebook from './facebook.png';
import twitter from './twitter.png';
import linkedin from './linkedin.png';
import instagram from './instagram.png';


export class SocialNetworks extends React.Component {
  render() {
    return (
      <div className="content socialNetworks">
        <div className="item">
          <Link to=""><img src={linkedin} alt="LinkedIn logo"/><h3>LinkedIn</h3></Link>
        </div>
        <div className="item">
          <Link to=""><img src={twitter} alt="Twitter logo"/><h3>Twitter</h3></Link>
        </div>
        <div className="item">
          <Link to=""><img src={facebook} alt="Facebook logo"/><h3>Facebook</h3></Link>
        </div>
        <div className="item">
          <Link to=""><img src={instagram} alt="Instagram logo"/><h3>Instagram</h3></Link>
        </div>
      </div>
    );
  }
}

