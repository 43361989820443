import React from "react";

import { Header } from "../scenes/Header/Header.js"

import { FlagVideo } from "../scenes/FlagVideo/FlagVideo.js"
import { Mission } from "../scenes/Mission/Mission.js"
import { Feed } from "../scenes/Feed/Feed.js"
import { Focus } from "../scenes/Focus/Focus.js"

import { Footer } from "../scenes/Footer/Footer.js"

import "./pageStyle.css";


export class Home extends React.Component {
  render() {
    return (
      <div className="page home">
        <Header />

        <FlagVideo />

        <Mission />

        <Focus />

        <Feed />

        <Footer />
      </div>
    );
  }
}