import React from "react";


import "./SupportUs.css";


export class SupportUs extends React.Component {
  render() {
    return (
      <div className="content supportus">
        <div className="item">
          <p>Devenir adhérent</p>
        </div>
        <div className="item">
          <p>Faire un don</p>
        </div>
      </div>
    )
  }
}
