import React from "react";
import axios from 'axios';


import { Text } from '../../containers/Language';


import "./FormContact.css";


export class FormContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forename: '',
      lastname: '',
      email: '',
      organisation: '',
      content: ''
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

handleFormSubmit(event) {
    event.preventDefault();
    if (this.state.forename !== "" && this.state.lastname !== "" && this.state.email !== "" && this.state.content !== "") {
      const API_PATH = 'https://api.planetearthnow.org/contact/contact_form.php';
      axios({
        method: 'post',
        url: API_PATH,
        headers: {
          'content-type': 'application/json'
        },
        data: this.state
      })
      .then(result => {
          console.log(result.data)
          this.setState({
              dataSent: result.data.sent,
          })
          console.log(this.state)
          this.setState({
            forename: '',
            lastname: '',
            email: '',
            organisation: '',
            content: ''
          })
      })
      .catch(error => this.setState({
          error: error.message
      }));
    };
  }

  render() {
    return (
      <div className="content formContact">
        <form acceptCharset="UTF-8" onSubmit={this.handleFormSubmit}>
          <ol>
            <li>
              <TextInput
                label={<Text tid="contact.contactUs.content.form.forename" />}
                id="forename"
                type="text"
                value={this.state.forename}
                onChange={e => this.setState({ forename: e.target.value })}
                minLength="1"
                maxLength="255"
                asterisk={true}
                required
              />
            </li>
            <li>
              <TextInput
                label={<Text tid="contact.contactUs.content.form.lastname" />}
                id="lastname"
                type="text"
                value={this.state.lastname}
                onChange={e => this.setState({ lastname: e.target.value })}
                minLength="1"
                maxLength="255"
                asterisk={true}
                required
              />
            </li>
            <li>
              <TextInput
                label={<Text tid="contact.contactUs.content.form.email" />}
                id="email"
                type="email"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                minLength="1"
                maxLength="255"
                asterisk={true}
                required
              />
            </li>
            <li>
              <TextInput
                label={<Text tid="contact.contactUs.content.form.organisation" />}
                id="organisation"
                type="text"
                value={this.state.organisation}
                onChange={e => this.setState({ organisation: e.target.value })}
                minLength="1"
                maxLength="255"
                asterisk={false}
              />
            </li>
            <li>
              <div className="text-input">
                <label htmlFor="message">{<Text tid="contact.contactUs.content.form.message" />}<span>*</span></label>
                <textarea id="message" required value={this.state.content} onChange={e => this.setState({ content: e.target.value })} minLength="1" maxLength="2048"></textarea>
              </div>
            </li>
          </ol>
          <div className="button">
            {this.state.dataSent ?
              <p className="good">
                {<Text tid="contact.contactUs.content.form.validation" />}
              </p>
              :
              <p></p>
            }
            <input type="submit" value="Envoyer" />
          </div>
        </form>
      </div>
    );
  }
}



const TextInput = ({ label, type = "text", asterisk, id, value, ...props }) => (
  <div className="text-input">
    {label && <label htmlFor={id}>{label}<span>{asterisk ? "*" : " "}</span><span className="facultatif"><br></br>{asterisk ? "" : (<Text tid="contact.contactUs.content.form.optional" />)}</span></label>}
    <input id={id} type={type} value={value} {...props} />
  </div>
);

