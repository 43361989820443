import React, { Component } from "react";


import {Displayer} from '../../components/Displayer/Displayer.js'


import "./Issues.css";


// let data = [
//   {
//     name: "Biodiversité",
//     src: 'https://fr.cdn.v5.futura-sciences.com/buildsv6/images/wide1920/c/4/8/c487d451a5_121650_04.jpg'
//   },
//   {
//     name: "Pêche abusive",
//     src: "https://www.vipinfos.net/wp-content/uploads/2020/12/51983117-39766149.jpg"
//   },
//   {
//     name: "Centrales à charbon",
//     src: "https://www.francetvinfo.fr/image/759ufeel7-75e5/1500/843/11012987.jpg"
//   },
//   {
//     name: "Crime d'écocide",
//     src: "https://static.politis.fr/medias/articles/2019/12/le-crime-decocide-devant-le-parlement-41133/thumbnail_large-41133.jpg"
//   },
//   {
//     name: "Taxe carbone",
//     src: "https://img.20mn.fr/IEubXMrBSz2J7F5EU2IavQ/648x415_illustration-taxe-carbone.jpg"
//   },
//   {
//     name: "Pesticides",
//     src: "https://tse1.mm.bing.net/th?id=OIP.oEiKXnVM5FaaUc7K1E5yHAHaFB&pid=Api"
//   },
//   {
//     name: "Énergies fossiles",
//     src: "https://petitions.leslignesbougent.org/wp-content/uploads/2020/01/energies-fossiles.jpg"
//   },
//   {
//     name: "Plastique",
//     src: "https://www.emballagesmagazine.com/mediatheque/1/6/3/000030361_600x400_c.jpg"
//   },
//   {
//     name: "Déforestation",
//     src: "https://lvdneng.rosselcdn.net/sites/default/files/dpistyles_v2/ena_16_9_extra_big/2019/12/14/node_680720/44462406/public/2019/12/14/B9721931996Z.1_20191214171903_000%2BGI2F4GGG3.1-0.jpg?itok=osWnkSVp1576340350"
//   },
//   {
//     name: "Émission des gaz à effet de serre",
//     src: "https://radiostarcom.com/upload/news/main/5bf69583519056.24532155.jpg?=1590919995"
//   },
//   {
//     name: "Agriculture",
//     src: "https://lejournal.cnrs.fr/sites/default/files/styles/visuel_principal/public/assets/images/rea_187807_047_72dpi.jpg?itok=vRxtpni0"
//   }
// ];

let data = [
  {
    name: "Notre alimentation",
    src: "https://cdn.radiofrance.fr/s3/cruiser-production/2018/11/c99f84e0-72f2-427a-94cb-70aacf714a56/870x489_gettyimages-861188910.jpg",
    content: null
  },
  {
    name: "Notre façon de nous déplacer",
    src: "https://www.meudon.fr/wp-content/uploads/sites/5/2020/08/En_Tete_Transports-pt.jpg",
    content: null
  },{
    name: "Les énergies que nous utilisons",
    src: "https://cdn.futura-sciences.com/buildsv6/images/wide1920/3/d/2/3d247374f3_50173013_e-nergie-verte-e-oliennes.jpg",
    content: null
  },{
    name: "Les lieux où nous vivons",
    src: "https://www.nantaise-habitations.fr/wp-content/uploads/2020/09/R%C3%A9sidence-du-Tertre-28-scaled.jpg",
    content: null
  },{
    name: "Notre rapport à la consommation",
    src: "https://www.lebrief.ma/sites/default/files/styles/facebook/public/2020-09/consommation.jpg?h=d9151913&itok=KRp_uozh",
    content: null
  },
]

export class Issues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      activeId: null
    };
  }

  render() {
    let acts = this.state.data.map((item, index) => {
      return (
        <Item key={index}
          index={index}
          item={item}
        />
      )
    });

    return (
      <ul className="content issues">
        {acts}
      </ul>
    );
  }
};


class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: null
    };
  }

  render() {
    return (
      <li className={"act "  + ((this.state.activeId === this.props.index) ? 'act-open' : '')} onClick={() => this.setState({activeId: (this.state.activeId === this.props.index) ? null : this.props.index})} style={{backgroundImage: "url(" + this.props.item.src + ")"}}>
        <h3>{this.props.item.name}</h3>
        <Displayer
          display={this.state.activeId === this.props.index}
          handleClose={() => this.setState({activeId: null})}
          title={this.props.item.name}
          content={<p>{this.props.item.content}</p>}  
      />
      </li>
    );
  }
}