import React from "react";


import { Text } from '../containers/Language';


import { Header } from "../scenes/Header/Header.js"
import { HeroHeader } from "../scenes/HeroHeader/HeroHeader.js"
import { Module } from "../components/Module/Module.js"

import { Tabs } from "../scenes/MediasAccordion/MediasAccordion.js"
import { Issues } from "../scenes/Issues/Issues.js";
import { History } from "../scenes/History/History.js"

import { Footer } from "../scenes/Footer/Footer.js"


import "./pageStyle.css";


export class Education extends React.Component {
  render() {
    return (
      <div className="page education">
        <Header />

        <HeroHeader 
          title={<Text tid="education.header.title" />}
          introduction={<Text tid="education.header.introduction" />}
          quote={<Text tid="education.header.quote" />}
          author={<Text tid="education.header.author" />}
          path="education"
        />
        
        <Module
          moduleTitle={<Text tid="education.issues.title" />}
          displaySuggestions={true}
          bodyIntroductionText={<Text tid="education.issues.introduction" />}
          bodyContent={<Issues />}
        />

        <Module
          moduleTitle={<Text tid="education.medias.title" />}
          displaySuggestions={true}
          bodyIntroductionText={<Text tid="education.medias.introduction" />}
          bodyContent={<Tabs />}
        />        

        <Module
          moduleTitle={<Text tid="education.history.title" />}
          displaySuggestions={true}
          bodyIntroductionText={<Text tid="education.history.introduction" />}
          bodyContent={<History />}
        />

        <Footer />
      </div>
    );
  }
}