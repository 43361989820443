import React, { Fragment } from "react";

import "./Displayer.css";

import close from "./close.png";


export class Displayer extends React.Component {
  constructor() {
    super();
    this.state = {
      isMobile: (window.innerWidth > 680 ? false : true),
    }
  }

  updateDimensions = () => {
    this.setState({ isMobile: (window.innerWidth > 680 ? false : true) });
  }

  componentDidMount(){
    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(){
    let state = null
    if( this.props.display ) {state = 'hidden'} else {state = 'auto'}
    return document.querySelector('html').style.overflow = state
  }
  
  render() {
    let closeButton = <Fragment>
      <div className="close" onClick={() => {this.props.handleClose()}}>
        <img src={close} alt="close icon" onClick={() => {this.props.handleClose()}}/>
      </div>
    </Fragment>
    
    return (
      <div className={"displayer "  + (this.props.display ? 'displayer-open' : '')} onClick={() => {this.props.handleClose()}} >
        {this.state.isMobile ? closeButton : null}
        <div className="wrapper" onClick={(e) => {e.stopPropagation()}}>
            <div className="header">
              <h3>{this.props.title}</h3>
              {this.state.isMobile ? null : closeButton}
            </div>
            <div className="content">
              {this.props.content}
            </div>
          </div>
      </div>
    )}
}
