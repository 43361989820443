import React, { Fragment } from "react";
  

import "./IndividualActs.css";


let data = [
  {
    name: 'Transport',
    list: 
    [
      {
        name: "Favoriser les trajets à pied ou en vélo",
        effect: "health"
      },
      {
        name: "Donner la priorité au co-voiturage",
        effect: "save"
      },
      {
        name: "Utiliser les transports publics"
      },
      {
        name: "Éviter de voyager en avion"
      },
      {
        name: "Acheter une voiture économe",
        effect: "save"
      }
    ]  
  },
  {
    name: 'Pollution',
    list: 
    [
      {
        name: "Réparer au lieu de racheter, donner au lieu de jeter",
        effect: "save"
      },
      {
        name: "Éteindre votre frigo lors d'absences prolongées",
        effect: "save"
      },
      {
        name: "Privilégier le stockage sur disque dur plutôt que sur un « cloud »"
      },
      {
        name: "Prolongez la durée de vos objets électroniques au maximum",
        effect: "save"
      },
      {
        name: "Ne rien jeter par terre"
      },
      {
        name: "Coller un stop publicité sur votre boîte aux lettres"
      },
      {
        name: "Planter des arbres dans votre communauté"
      }
    ]  
  },
  {
    name: 'Consommation responsable',
    list: 
    [
      {
        name: "Acheter des produits labellisés éthiques"
      },
      {
        name: "Faire vos courses avec un sac réutilisable et refuser les sacs et sachets en plastique"
      },
      {
        name: "Rapporter ses médicaments en pharmacie"
      },
      {
        name: "Couper les tubes de crème pour récupérer le fond",
        effect: "save"
      },
      {
        name: "Utiliser un filtre à eau et une gourde"
      },
      {
        name: "Utiliser un moteur de recherche écologique"
      },
      {
        name: "Choisir une banque éthique"
      }
    ]  
  },
  {
    name: "Économies d'énergies",
    list: 
    [
      {
        name: "Éteindre les lumières et débranchez vos appareils",
        effect: "save"
      },
      {
        name: "Utiliser des ampoules LED plutôt que des halogènes",
        effect: "save"
      },
      {
        name: "Réduire le chauffage",
        effect: "save"
      },
      {
        name: "Éviter d'utiliser la climatisation",
        effect: "save"
      },
      {
        name: "Effacez vos e-mails"
      },
      {
        name: "Éviter la congélation"
      },
      {
        name: "Vérifier votre isolation",
        effect: "save"
      },
      {
        name: "Choisissez un fournisseur d’énergie renouvelable"
      }
    ]  
  },
  {
    name: "Recyclage",
    list: 
    [
      {
        name: "Triez vos déchets"
      },
      {
        name: "Recycler les différents plastiques"
      },
      {
        name: "Organiser le recyclage et un compost avec vos voisins ou co-propriétaires"
      },
      {
        name: "Choisir des piles rechargeables",
        effect: "save"
      },
      {
        name: "Réutiliser l’eau usagée pour arroser les plantes",
        effect: "save"
      },
      {
        name: "Utiliser du papier recyclé"
      }
    ]  
  },
  {
    name: "Alimentation",
    list: 
    [
      {
        name: "Consommez des fruits et légumes de saison",
        effect: "health"
      },
      {
        name: "Acheter bio et local",
        effect: "health"
      },
      {
        name: "Diminuer votre consommation de boeuf et de produits laitiers",
        effect: "save"
      },
      {
        name: "Proscrire les produits avec l'huile de palme"
      },
      {
        name: "Consommez des poissons qui proviennent d’une pêche éthique"
      },
      {
        name: "Renseignez-vous sur l'impact environnemental des aliments que vous consommez grâce à des outils comme Yuka"
      },
      {
        name: "Consommez des invendus alimentaires à prix réduit grâce à des applications comme Phénix ou Too good to go",
        effect: "save"
      }
    ]  
  }
];


export class IndividualActs extends React.Component {
  constructor() {
    super();
    this.state = {
      data: data,
      isMobile: (window.innerWidth > 680 ? false : true),
      activeId: null
    }
    this.deploy = this.deploy.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  deploy(event, index) {
    event.stopPropagation();
    this.setState({
      activeId: (this.state.activeId === index) ? null : index,
    });
  }

  updateDimensions = () => {
    this.setState({ isMobile: (window.innerWidth > 680 ? false : true) });
  }

  addIcon(item){
    // list.map((item)=> {
      if( item === "health" ){ return <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#008B8B" stroke="black" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1" d="M18.905 14c-2.029 2.401-4.862 5.005-7.905 8-5.893-5.8-11-10.134-11-14.371 0-6.154 8.114-7.587 11-2.676 2.865-4.875 11-3.499 11 2.676 0 .784-.175 1.572-.497 2.371h-6.278c-.253 0-.486.137-.61.358l-.813 1.45-2.27-4.437c-.112-.219-.331-.364-.576-.38-.246-.016-.482.097-.622.299l-1.88 2.71h-1.227c-.346-.598-.992-1-1.732-1-1.103 0-2 .896-2 2s.897 2 2 2c.74 0 1.386-.402 1.732-1h1.956c.228 0 .441-.111.573-.297l.989-1.406 2.256 4.559c.114.229.343.379.598.389.256.011.496-.118.629-.337l1.759-2.908h8.013v2h-5.095z"/></svg> }
      if( item === "save" ){ return <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#F5DADF" stroke="black" strokeLinejoin="round" strokeLinecap="round" strokeWidth="20" d="M560 224h-29.5c-8.8-20-21.6-37.7-37.4-52.5L512 96h-32c-29.4 0-55.4 13.5-73 34.3c-7.6-1.1-15.1-2.3-23-2.3H256c-77.4 0-141.9 55-156.8 128H56c-14.8 0-26.5-13.5-23.5-28.8C34.7 215.8 45.4 208 57 208h1c3.3 0 6-2.7 6-6v-20c0-3.3-2.7-6-6-6c-28.5 0-53.9 20.4-57.5 48.6C-3.9 258.8 22.7 288 56 288h40c0 52.2 25.4 98.1 64 127.3V496c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-48h128v48c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-80.7c11.8-8.9 22.3-19.4 31.3-31.3H560c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16zm-128 64c-8.8 0-16-7.2-16-16s7.2-16 16-16s16 7.2 16 16s-7.2 16-16 16zM256 96h128c5.4 0 10.7.4 15.9.8c0-.3.1-.5.1-.8c0-53-43-96-96-96s-96 43-96 96c0 2.1.5 4.1.6 6.2c15.2-3.9 31-6.2 47.4-6.2z" /></svg> }
    // })
  }

  getItems (list) {
    let items = list.map((item) => {
      return (
        <li key={item.name} className="act-item">
          <div><span>{item.name}</span>{item.effect != null ? <div className="icon">{this.addIcon(item.effect)}</div> : null }</div>
        </li>
      )
    });

    return (
      items
    )
  }

  render() {
    let acts = this.state.data.map((item, index) => {
      return (
        <ul key={item.name} className="act">
          {this.state.isMobile ? 
          <Fragment>
            <div className="actHeader"  onClick={(e) => {this.deploy(e, index)}}>
              <h3>{item.name}</h3>
              <div className={"more " + (this.state.activeId === index ? 'open' : '')}>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className={"actContent " + (this.state.activeId === index ? 'open' : '')}>
              {this.getItems(item.list)}
            </div>
          </Fragment>
          :
          <Fragment>
            <h3>{item.name}</h3>
            {this.getItems(item.list)}
          </Fragment>
          } 
        </ul>
      )
    });

    return (
      <div className="content acts">
        {acts}
      </div>
    );
  }
};