import React from "react";

import { Link } from "react-router-dom";
import { Text } from '../../containers/Language';

import "./Mission.css";

import missionImage from "./bankimoon.jpg";


export class Mission extends React.Component {
  render() {
    return (
      <div className="mission">

        <div className="missionContent">
          <h2><Text tid="home.mission.name" /></h2>
          <p><Text tid="home.mission.description" /></p>
          <Link to="/association"><button type="button"><Text tid="home.mission.button" /></button></Link>
        </div>

        <div className="missionImage">
          <img src={missionImage} alt="Bankimoon SG COP 21"/>
          <p><Text tid="home.mission.imageCopyright" /></p>
        </div>
        
      </div>
    );
  }
}