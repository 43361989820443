import fr from './fr.json';
import en from './en.json';
import es from './es.json';
import po from './po.json';

// const fetchShoppingItems = async () => {
//   const res = await fetch("https://api.planetearthnow.org/total");
//   return res.json()
// }

// let fr = {};

// fetchShoppingItems().then(result => {
//   fr = result.page
//   console.log(en)
// })

export const dictionaryList = { fr, en, es, po };

export const languageOptions = {
  fr: 'Français',
  en: 'English',
  es: "Espagnol",
  po: "Portugais"
};