import React from "react";
import "./FlagVideo.css";
import sample from './floating-flag.mp4';

export class FlagVideo extends React.Component {
  render() {
    return (
      <div className="wrapper--video">
        <video className='videoTag' autoPlay loop muted playsInline>
          <source src={sample} type='video/mp4' />
        </video>
      </div>
    );
  }
}