import React from "react";
import "./Quote.css";


export class Quote extends React.Component {
  render() {
    return (
      <div className="quote-wrapper">
        <div className="quote"><span>&ldquo;&nbsp;</span><p>{this.props.quote}</p><span>&bdquo;</span></div>
        <div className="author"><p>— {this.props.author}</p></div>
      </div>
    );
  }
}