import React, { Fragment } from "react";
import axios from 'axios';

import {Displayer} from '../../components/Displayer/Displayer.js'

import "./SideBanner.css";


export class SideBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: this.props.title,
      content: "",
      open: false
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(event) {
    event.preventDefault();
    if (this.state.content !== "...") {
      const API_PATH = 'https://api.planetearthnow.org/suggestions/suggestions_form.php';
      axios({
        method: 'post',
        url: API_PATH,
        headers: {
          'content-type': 'application/json'
        },
        data: this.state
      })
      .then(result => {
          console.log(result.data)
          this.setState({
              dataSent: result.data.sent,
          })
          console.log(this.state)
      })
      .catch(error => this.setState({
          error: error.message
      }));
    };
  }

  render() {
    let content = <Fragment>
      <form acceptCharset="UTF-8" onSubmit={this.handleFormSubmit}>
                  <ol>
                    <li>
                      <TextInput
                        label="Catégorie"
                        id="category"
                        type="text"
                        value={this.state.category}
                        minLength="1"
                        maxLength="255"
                        asterisk={true}
                        required
                        readOnly
                      />
                    </li>
                    <li>
                      <div className="text-input">
                        <label htmlFor="content">Contenu<span>*</span></label>
                        <textarea id="content" required value={this.state.content} onChange={e => this.setState({ content: e.target.value })} minLength="1" maxLength="2048"></textarea>
                      </div>
                    </li>
                  </ol>

                  <div className="button">
                    {this.state.dataSent ?
                      <p className="good">
                        Suggestion envoyée !
                      </p>
                      :
                      <p></p>
                    }
                    <input type="submit" value="Envoyer" />
                  </div>
                  
                </form>
    </Fragment>

    return (
      <div className="sidebanner">
          <div className="button" onClick={() => this.setState({open: (this.state.open === true) ? false : true})}>
            <p>Suggestions</p>
          </div>
          <Displayer
            display={this.state.open}
            handleClose={() => this.setState({open: false})}
            title={"Suggestions : " + this.props.title}
            content={content}  
          />
      </div>
    );
  }
}


const TextInput = ({ label, type = "text", asterisk, id, value, ...props }) => (
  <div className="text-input">
    {label && <label htmlFor={id}>{label}<span>{asterisk ? "*" : " "}</span><span className="facultatif"><br></br>{asterisk ? "" : " (facultatif)"}</span></label>}
    <input id={id} type={type} value={value} {...props} />
  </div>
);

