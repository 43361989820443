import React from "react";

import { Text } from '../../containers/Language';

import "./AboutUs.css";

export class AboutUs extends React.Component {
  render() {
    return (
      <div className="content aboutus">
        <div className="item">
          <h3><Text tid="association.aboutUs.mission.title" /></h3>
          <p><Text tid="association.aboutUs.mission.text" /></p>
        </div>
        <div className="item">
          <h3><Text tid="association.aboutUs.vision.title" /></h3>
          <p><Text tid="association.aboutUs.vision.text" /></p>
        </div>
        <div className="item">
          <h3><Text tid="association.aboutUs.values.title" /></h3>
          <p><Text tid="association.aboutUs.values.text" /></p>
        </div>
      </div>
    )
  }
}
