import React from "react";
import axios from 'axios';
import "./Counter.css";


export class Counter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: '',
      counter: this.props.reRender 
    }

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    const API_PATH = 'https://data.planetearthnow.org/mobilisation/counter.php';
    axios({
      method: 'get',
      url: API_PATH,
      headers: {
          'content-type': 'application/json'
      },
      data: this.state
    })
    .then(result => {
        // console.log(result.data)
        this.setState({
            count: result.data.count,
        })
        // console.log(this.state)
    })
    .catch(error => this.setState({
        error: error.message
    }));
  }

  componentDidUpdate() {
    if (this.state.counter > 0) {
      this.componentDidMount();
      this.setState({counter: 0})
    }
  }

  render() {
      return (
        <div className="counter-wrapper">
          <div className="counter">
            <p>Déjà <span>{this.state.count}</span> adhérents !</p>
          </div>
        </div>
      )
  }
};