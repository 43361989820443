import React from "react";

import { Link } from "react-router-dom";
import { Text } from '../../containers/Language';

import "./Focus.css";

import educationImage from './education.jpg';
import actionImage from './action.jpg';
import mobilisationImage from './mobilisation.jpeg';


export class Focus extends React.Component {
  render() {
    return (
      <div className="focus">

        <div className="focusHeader">
          <h2><Text tid="home.focus.header.name" /></h2>
          {/* <Quote 
            quote={<Text tid="focusHeader-quote" />}
            author={<Text tid="focusHeader-author" />} 
          /> */}
          <h3><Text tid="home.focus.header.description" /></h3>
        </div>

        <div className="focusContent">

          <div className="column column-education">
            <div className="columnImage">
              <img src={educationImage} alt="Education"/>
              <p><Text tid="home.focus.education.copyright" /></p>
            </div>
            <Link to="/education"><button type="button"><Text tid="home.focus.education.name" /></button></Link>
            <div className="columnQuote">
              <p><Text tid="home.focus.education.quote" /></p>
              <span><Text tid="home.focus.education.author" /></span>
            </div>
          </div>

          <div className="column column-action">
            <div className="columnImage">
              <img src={actionImage} alt="Action"/>
              <p><Text tid="home.focus.action.copyright" /></p>
            </div>
            <Link to="/action"><button type="button"><Text tid="home.focus.action.name" /></button></Link>
            <div className="columnQuote">
              <p className="actionP"><Text tid="home.focus.action.quote" /></p>
              <span><Text tid="home.focus.action.author" /></span>
            </div>
          </div>

          <div className="column column-mobilisation">
            <div className="columnImage">
              <img src={mobilisationImage} alt="Mobilisation"/>
              <p><Text tid="home.focus.mobilisation.copyright" /></p>
            </div>
            <Link to="/mobilisation"><button type="button"><Text tid="home.focus.mobilisation.name" /></button></Link>
            <div className="columnQuote">
              <p><Text tid="home.focus.mobilisation.quote" /></p>
              <span><Text tid="home.focus.mobilisation.author" /></span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}