import React from "react";

import "./History.css";

export class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="content history">
        <div className="item">
          <h3>La lutte pour la sauvegarde de notre environnement c’est une histoire dont nous sommes tous tributaires. Une histoire faite de convictions, d’inventions, de catastrophes et de solidarité. Revenons sur ses temps forts.</h3>
          <p>Diapositives textes et images en vis-à-vis</p>
        </div>
        <div className="item">
          <h3>Les gouvernements agissent pour la planète. Cette frise retrace tous les sommets, accords internationaux et lois françaises au fil du temps. En cliquant sur les différentes dates vous découvrirez leur impact sur nos vies.</h3>
          <p>Frise chronologique interactive</p>
        </div>
      </div>
    );
  }
}
