import React from "react";

import {ModuleHeader} from '../../components/ModuleHeader/ModuleHeader.js';
import {ModuleIntroduction} from '../../components/ModuleIntroduction/ModuleIntroduction.js';

import "./Module.css";


export class Module extends React.Component {
  render() {
    return (
      <section id={this.props.id}>
        <div className="module">
          <ModuleHeader
            title={this.props.moduleTitle}
            suggestions={this.props.displaySuggestions}
          />
          <div className="moduleBody">
            <ModuleIntroduction 
              text={this.props.bodyIntroductionText}
            />
            {this.props.bodyContent}  
          </div>
        </div>
      </section>
    );
  }
}