import React from "react";

import "./Partners.css";

export class Partners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="content partners">
        <p>Mosaïque avec les partenaires</p>
      </div>
    );
  }
}
