import React from "react";

import { HashLink as Link } from 'react-router-hash-link';
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector.js"
import { Text } from '../../containers/Language';

import "./Header.css";

import logo from "./logo.png";


export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: (window.innerWidth > 680 ? false : true),
      active: false,
      open: "",
      display: "none",
      opacity: "0",
      width: "0%",
      height: "0%",
      left: "100vw"
    }
    this.toggle = this.toggle.bind(this);
  }

  updateDimensions = () => {
    this.setState({ isMobile: (window.innerWidth > 680 ? false : true) });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  toggle() {
    const open = this.state.open === ' open' ? '' : ' open';
    const display = this.state.display === 'flex' ? 'none' : 'flex'
    const opacity = this.state.opacity === '1' ? '0' : '1'
    const width = this.state.width === '100%' ? '0%' : '100%'
    const height = this.state.height === '100%' ? '0%' : '100%'
    const left = this.state.left === '0vw' ? '100vw' : '0vw'
    this.setState({
      active: (this.state.active === true) ? false : true,
      open: open,
      display: display,
      opacity: opacity,
      width: width,
      height: height,
      left: left
    });
    // let state = null
    // if( this.state.active ) {state = 'hidden'} else {state = 'auto'}
    // return document.querySelector('html').style.overflow = state
  }

  render() {
    return (
      <header className={this.state.active ? 'fixed' : ''}>
        <div className="header-logo">
          <Link to="/"><img src={logo} alt="Planet Earth Now - Drapeau"/></Link>
          <div className="title"><span><Text tid="header.title" /></span></div>
        </div>
        {this.state.isMobile ? 
        <div className="header-navigation--mobile">
          <div className={"hamburger-menu" + this.state.open} onClick={this.toggle}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <nav className={this.state.open}>
            <ol>
              <li><Link to="/association"><Text tid="header.navigation.1.name" />
                    {/* <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" color="#000"><path d="M0 0h24v24H0z" fill="none"></path><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg> */}
                  </Link>
                {/* <ol>
                  <li className="subLink"><Link to="/association#about_us"><Text tid="header.navigation.1.submenus.1.name" /></Link></li>
                  <li className="subLink"><Link to="/association#projects"><Text tid="header.navigation.1.submenus.2.name" /></Link></li>
                  <li className="subLink"><Link to="/association#support_us"><Text tid="header.navigation.1.submenus.3.name" /></Link></li>
                </ol> */}
              </li>
              <li className="mainLink"><Link to="/education"><Text tid="header.navigation.2.name" /></Link></li>
              <li className="mainLink"><Link to="/action"><Text tid="header.navigation.3.name" /></Link></li>
              <li className="mainLink"><Link to="/mobilisation"><Text tid="header.navigation.4.name" /></Link></li>
              <li><Link to="/"><Text tid="header.navigation.5.name" /></Link></li>
              <li><Link to="/contact"><Text tid="header.navigation.6.name" /></Link></li>
            </ol>
            <div className="flags">
              <LanguageSelector />
            </div>
          </nav>
        </div>
        : 
        <div className="header-navigation">
          <div className="flags">
            <LanguageSelector />
          </div>
          <nav>
            <ol>
              <li><Link to="/association"><Text tid="header.navigation.1.name" /><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" color="#000"><path d="M0 0h24v24H0z" fill="none"></path><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg></Link>
                <ol>
                  <li className="subLink"><Link to="/association#about_us"><Text tid="header.navigation.1.submenus.1.name" /></Link></li>
                  <li className="subLink"><Link to="/association#projects"><Text tid="header.navigation.1.submenus.2.name" /></Link></li>
                  <li className="subLink"><Link to="/association#support_us"><Text tid="header.navigation.1.submenus.3.name" /></Link></li>
                </ol>
              </li>
              <li className="mainLink"><Link to="/education"><Text tid="header.navigation.2.name" /></Link></li>
              <li className="mainLink"><Link to="/action"><Text tid="header.navigation.3.name" /></Link></li>
              <li className="mainLink"><Link to="/mobilisation"><Text tid="header.navigation.4.name" /></Link></li>
              <li><Link to="/"><Text tid="header.navigation.5.name" /></Link></li>
              <li><Link to="/contact"><Text tid="header.navigation.6.name" /></Link></li>
            </ol>
          </nav>
        </div>
        }
      </header>
    );
  }
}