import React from "react";

import { Link } from "react-router-dom";
import { Text } from '../../containers/Language';

import "./Footer.css";

import facebook from './facebook.png';
import twitter from './twitter.png';
import linkedin from './linkedin.png';
import instagram from './instagram.png';


export class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      isMobile: (window.innerWidth > 680 ? false : true),
    }
  }

  updateDimensions = () => {
    this.setState({ isMobile: (window.innerWidth > 680 ? false : true) });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  render() {
    let newDate = new Date();
    let year = newDate.getFullYear();
    return (
      <footer>
        <div className="footer-socialNetworks">
          <Link to=""><img src={linkedin} alt="LinkedIn logo"/></Link>
          <Link to=""><img src={facebook} alt="Facebook logo"/></Link>
          <Link to=""><img src={twitter} alt="Twitter logo"/></Link>
          <Link to=""><img src={instagram} alt="LinkedIn logo"/></Link>
        </div>
        <div className="footer-specials">
          <div>
            <Link to=""><Text tid="footer.copyright" /></Link>
            <Link to=""><Text tid="footer.RGPD" /></Link>
          </div>
          <p>@{year}</p>
        </div>
      </footer>
    );
  }
}