import React from "react";


import { Header } from "../scenes/Header/Header.js"
import { Module } from "../components/Module/Module.js"

import { FormContact } from "../scenes/FormContact/FormContact.js"
import { SocialNetworks } from "../scenes/SocialNetworks/SocialNetworks.js"
import { AddressMap } from "../scenes/AddressMap/AddressMap.js"

import { Footer } from "../scenes/Footer/Footer.js"


import { Text } from '../containers/Language';


import "./pageStyle.css";


export class Contact extends React.Component {
  render() {
    return (
      <div className="page contact">
        <Header />

        <Module
          moduleTitle={<Text tid="contact.contactUs.title" />}
          displaySuggestions={false}
          bodyIntroductionText={null}
          bodyContent={<FormContact />}
        />
        
        <Module
          moduleTitle={<Text tid="contact.followUs.title" />}
          displaySuggestions={false}
          bodyIntroductionText={null}
          bodyContent={<SocialNetworks />}
        />

        <Module
          moduleTitle={<Text tid="contact.findUs.title" />}
          displaySuggestions={false}
          bodyIntroductionText={null}
          bodyContent={<AddressMap />}
        />

        <Footer />
      </div>
    );
  }
}