import React, { Fragment } from "react";

import { Link } from "react-router-dom";

import "./MediasAccordion.css";

import frFlag from "../../languages/FR.svg";
import enFlag from "../../languages/EN.svg";
import esFlag from "../../languages/ES.svg";
import poFlag from "../../languages/PO.svg";

const flags = [frFlag, enFlag, esFlag, poFlag];

let data = [
  {
    name: 'À visionner',
    list: 
    [
      {
        name: "Interviews",
        type: "interview",
        list: 
        [
          {
            title: "GIEC : Le climat survivra-t-il au capitalisme ? Jouzel & Larrouturou",
            url: "https://www.youtube.com/embed/tUml_4KZKhk",
            date: "27/09/2019",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Jancovici : Climat, les leçons du choc Covid - Be Smart",
            url: "https://www.youtube.com/embed/QrATizaiuLM",
            date: "08/10/2020",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Aurélien Barrau : le plus grand défi de l'humanité",
            url: "https://www.youtube.com/embed/ObcGvQuTf7k",
            date: "22/05/2018",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
            
          },
          {
            title: "Jeremy Rifkin : le New Deal Vert Mondial: De la bulle carbone a la solution du marché",
            url: "https://www.youtube.com/embed/d49ZHoClJf4",
            date: "23/10/2019",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Le message du chef Raoni pour la forêt amazonienne",
            url: "https://www.youtube.com/embed/RYp_gdi5GKM",
            date: "02/09/2019",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Serge Marti : Une planète à sauver : les 6 défis majeurs",
            url: "https://www.youtube.com/embed/HFTrQU77KfE",
            date: "06/03/2020",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Voices of Amerikua I Berito Kuwaruwa (Cobaría), Pueblo U'Wa - Colombia",
            url: "https://www.youtube.com/embed/ORa5hgKfQsQ",
            date: "14/07/2015",
            languages: [
              {
                id: 2,
                name: "es"
              }
            ]
          },
          {
            title: '"Estamos decepcionando o criador": Raoni inicia giro pelo mundo em "campanha" pelo Nobel da Paz',
            url: "https://www.youtube.com/embed/sUcz6QNyixc",
            date: "07/02/2020",
            languages: [
              {
                id: 3,
                name: "po"
              }
            ]
          }
        ]
      },
      {
        name: "Conférences",
        type: "conference",
        list: 
        [
          {
            title: "La permaculture : une voie pour commencer à faire autrement ? | Julien Le Breton | TEDxNouméa",
            url: "https://www.youtube.com/embed/0SFQize20uI",
            date: "12/12/2017",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Hubert Reeves · Protection de l'environnement",
            url: "https://www.youtube.com/embed/zYrebICtJ0A",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Colloque Homme, Environnement, Guerre : Conférence introductive, Dominique BOURG",
            url: "https://www.youtube.com/embed/Ny03pNZBHvY",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Cycle de conférences : L'environnement en question...",
            url: "https://www.youtube.com/embed/W9qup1_nE7s",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Gilles Boeuf - Conférence pour l'environnement",
            url: "https://www.youtube.com/embed/Slakhoh4ftM",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Who Is Responsible For Climate Change? – Who Needs To Fix It?",
            url: "https://www.youtube.com/embed/ipVxxxqwBQw",
            date: "21/06/2020",
            languages: [
              {
                id: 1,
                name: "en"
              }
            ]
          },
          {
            title: "Anybody can be a sustainability leader",
            url: "https://www.youtube.com/embed/yJKXQ0JHIvc",
            date: "05/10/2020",
            languages: [
              {
                id: 1,
                name: "en"
              }
            ]
          },
          {
            title: "Jane McDonald – Who is an Environmentalist?",
            url: "https://www.youtube.com/embed/7D9yJPkneG0",
            date: "02/10/2019",
            languages: [
              {
                id: 1,
                name: "en"
              }
            ]
          },
          {
            title: "What is biodiversity and why does it matter to us?",
            url: "https://www.youtube.com/embed/8vRTtXWLtOA",
            date: "29/07/2019",
            languages: [
              {
                id: 1,
                name: "en"
              }
            ]
          },
          {
            title: "Cómo ser ambientalista: Imperfecto pero activo",
            url: "https://www.youtube.com/embed/XFUAduO9UFs",
            date: "10/06/2019",
            languages: [
              {
                id: 2,
                name: "es"
              }
            ]
          }
        ]
      },
      {
        name: "Documentaires",
        type: "documentary",
        list: 
        [
          {
            title: "Legacy - Yann Arthus-Bertrand",
            url: "https://www.youtube.com/embed/dJC30mclOCM",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Demain - Cyril Dion & Mélanie Laurent",
            url: "https://www.youtube.com/embed/tmF9sN5O4Gs",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Désobéissant.e.s!",
            url: "https://player.vimeo.com/video/512564705",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "La face cachée des énergies vertes",
            url: "https://player.vimeo.com/video/512564705",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "An Inconvenient Sequel: Truth To Power",
            url: "https://www.youtube.com/embed/huX1bmfdkyA",
            languages: [
              {
                id: 1,
                name: "en"
              }
            ]
          },
          {
            title: "Before the Flood",
            url: "https://www.youtube.com/embed/D9xFFyUOpXo",
            languages: [
              {
                id: 1,
                name: "en"
              }
            ]
          },
          {
            title: "Mother of All Rivers",
            url: "https://player.vimeo.com/video/132559974",
            languages: [
              {
                id: 1,
                name: "en"
              }
            ]
          },
          {
            title: "Félix Rodríguez de la Fuente: El Animal Humano",
            url: "https://www.youtube.com/embed/_zDVtmhdF84",
            languages: [
              {
                id: 2,
                name: "es"
              }
            ]
          },
          {
            title: "Medioambiente: ¿apague y vámonos? | Fractal - Cap 030",
            url: "https://www.youtube.com/embed/IabB6w6lwJE",
            languages: [
              {
                id: 2,
                name: "es"
              }
            ]
          },
          {
            title: "Seaspiracy",
            url: "https://www.youtube.com/embed/1Q5CXN7soQg",
            languages: [
              {
                id: 1,
                name: "en"
              }
            ]
          }
        ]
      },
      {
        name: "Reportages",
        type: "report",
        list: 
        [
          {
            title: "Sur le front - Hugo Clément",
            url: "https://www.youtube.com/embed/e9l114huGLc",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Ernst Zürcher, Expert en langue des bois",
            url: "https://www.youtube.com/embed/frI6cE8wQBg",
            languages: [
              {
                id: 0,
                name: "fr"
              }
            ]
          },
          {
            title: "Le Ciel Foundation - The Twelve Film",
            url: "https://www.youtube.com/embed/mSGxynhmFp8",
            date: "",
            languages: [
              {
                id: 1,
                name: "en"
              }
            ]
          }
        ]
      }
    ]  
  }, 
  {
    name: 'À lire',
    list: 
    [
      {
        name: "Livres",
        type: "book",
        list: 
        [
          {
            title: "+4°C",
            author: "Xavier Montserrat",
            src: "https://images.epagine.fr/313/9782212563313_1_75.jpg",
            url: "https://www.furet.com/livres/4-c-xavier-montserrat-9782212563313.html",
            quoteList: 
            [
              {
                quote: "Après 20 ans d'échec de la diplomatie climatique, l'action citoyenne est la seule véritable alternative pour agir sur le climat.",
                page: "4ème de couverture"
              },
              {
                quote: "Les clefs pour comprendre sont les clefs pour agir.",
                page: 202
              },
              {
                quote: "Chaque citoyen a vocation à devenir sentinnelle du climat.",
                page: 162
              }
            ]
          },
          {
            title: "Vers une révolution positive",
            author: "Positive Planet sous la direction de Jacques Attali",
            src: "https://www.fayard.fr/sites/default/files/images/livres/couv/9782213711812-001-T.jpeg",
            url: "",
            quoteList: 
            [
              {
                quote: "Ce livre est le fruit d’une démarche inédite qui veut rendre audible la parole de tous, et singulièrement celle des jeunes générations, qui demandent une « révolution positive ». Pour cela, 50 000 citoyens du monde entier ont répondu à une consultation."
              }
            ]
          },
          {
            title: "Rouge Carbone",
            author: "Laurent Fabius",
            src: "https://www.editions-observatoire.com/sites/default/files/1599183016_9791032913895_v100.jpg",
            url: "",
            quoteList: 
            [
              {
                quote: "Il s'agit d'élargir les droits de l'homme au droit de l'environnement.",
                page: 166
              },
              {
                quote: "Renforcer la prise de conscience et donc développer l'information. Nous ne pouvons nous contenter, nous les scientifiques, les industriels, les politiques, d'agir dans nos domaines respectifs sans nous tourner vers l'opinion publique, et plus particulièrement, vers la jeunesse.",
                page: 165
              }
            ]
          },
          {
            title: "Petit manuel de résistance contemporaine",
            author: "Cyril Dion",
            src: "https://images-na.ssl-images-amazon.com/images/I/81OwvAtVSOL.jpg",
            url: "",
            quoteList: 
            [
              {
                quote: "Que faire face à l’effondrement écologique qui se produit sous nos yeux ?",
              }
            ]
          },
          {
            title: "Le changement climatique expliqué à ma fille",
            author: "Jean-Marc Jancovici",
            src: "https://ref.lamartinieregroupe.com/media/9782020965972/grande/96597_couverture_Hres_0.jpg",
            url: "",
            quoteList: 
            [
              {
                quote: "",
                page: ""
              }
            ]
          },
          {
            title: "Le New Deal Vert Mondial",
            author: "Jeremy Rifkin",
            src: "https://m.media-amazon.com/images/I/51jZcAADiLL.jpg",
            url: "",
            quoteList: 
            [
              {
                quote: "Après avoir théorisé la troisième révolution industrielle, Jeremy Rifkin développe son grand projet de New Deal vert mondial. Il s'agit d'un véritable plan de transformation de la société pour faire face au changement climatique en construisant un monde post-énergies fossiles. Le temps nous est compté et le consensus scientifique ne peut plus être remis en question : le dérèglement climatique dû à l'homme  et issu de la combustion de matières fossiles va mener l'humanité à la sixième grande extinction de la vie sur Terre. Mais partout les solutions existent et sont à notre portée. Aujourd'hui, les intérêts des dirigeants politiques, économiques et financiers convergent avec ceux des citoyens : c'est ce que démontre le célèbre prospectiviste. Voici un ouvrage particulièrement documenté qui redonne de l'espoir et plus que jamais l'envie d'agir pour la planète.",
                page: "4ème de couverture"
              }
            ]
          },
          {
            title: "How to thrive in the next economy",
            author: "John Thackara",
            src: "https://img1.od-cdn.com/ImageType-100/5818-1/%7B34EF3596-64A2-4E96-8649-B94E4F6E0670%7DImg100.jpg",
            url: "",
            quoteList: 
            [
              {
                quote: "",
                page: ""
              }
            ]
          },
          {
            title: "Designing Climate Solutions",
            author: "Hal Harvey",
            src: "https://images-na.ssl-images-amazon.com/images/I/81jg84kO5-L.jpg",
            url: "",
            quoteList: 
            [
              {
                quote: "",
                page: ""
              }
            ]
          },
          {
            title: "The future we choose",
            author: "Christiana Figueres",
            src: "https://images-na.ssl-images-amazon.com/images/I/81JAKWRKSJL.jpg",
            url: "",
            quoteList: 
            [
              {
                quote: "",
                page: ""
              }
            ]
          }      
        ]
      },
      {
        name: "Articles",
        type: "article",
        list: 
        [
          {
            img: "https://www.slate.fr/sites/default/files/styles/1060x523/public/aquaponie.jpg",
            title: "La révolution aquaponique aura t-elle lieu ?",
            author: "Chisato Goya",
            newspaper: "Slate",
            date: "22/05/2015",   
            url: "https://www.slate.fr/story/101401/poissons-plantes-bacterie-revolution-aquaponie",
            quoteList: 
            [
              {
                quote: "Les clefs pour comprendre sont les clefs pour agir.",
              },
              {
                quote: "Chaque citoyen a vocation à devenir sentinnelle du climat.",
              }
            ]
          },
          {
            img: "https://www.slate.fr/sites/default/files/styles/1060x523/public/growth_0.jpg",
            title: "Non la croissance économique n’est pas limitée par l’environnement",
            author: "non renseigné",
            newspaper: "Slate",
            date: "31/12/2016",   
            url: "https://www.slate.fr/story/132974/croissance-economique-environnement",
            quoteList: 
            [
              {
                quote: "Les clefs pour comprendre sont les clefs pour agir.",
              },
              {
                quote: "Chaque citoyen a vocation à devenir sentinnelle du climat.",
              }
            ]
          },
          {
            img: "https://www.slate.fr/sites/default/files/styles/1060x523/public/vanveenjf-1143060-unsplash.jpg",
            title: "Pourquoi pense-t-on que les produits bio sont moins efficaces?",
            author: "Angela Sutan et Gilles Grolleau",
            newspaper: "Slate",
            date: "12/03/2019",   
            url: "https://www.slate.fr/story/174504/produits-bio-moins-bons-nettoyants-verts-efficacite",
            quoteList: 
            [
              {
                quote: "Les clefs pour comprendre sont les clefs pour agir.",
              },
              {
                quote: "Chaque citoyen a vocation à devenir sentinnelle du climat.",
              }
            ]
          },
          {
            img: "https://www.actu-environnement.com/images/illustrations/news/36082_large.jpg",
            title: "Comment donner une valeur à la biodiversité ?",
            author: "Rachida Boughriet",
            newspaper: "Actu Environnement",
            date: "10/09/2020",   
            url: "https://www.actu-environnement.com/ae/news/conseil-analyse-economique-note-reponse-economique-erosion-biodiversite-plan-relance-36082.php4",
            quoteList: 
            [
              {
                quote: "Les clefs pour comprendre sont les clefs pour agir.",
              },
              {
                quote: "Chaque citoyen a vocation à devenir sentinnelle du climat.",
              }
            ]
          },
          {
            img: "https://www.college-de-france.fr/media/pierre-rosanvallon/UPL1043452453582244059_VieDesIdees.jpg",
            title: "« Le Génie Écologique » : Le principe de compensation",
            author: "Matthieu Calame",
            newspaper: "La vie des idées",
            date: "07/01/2021",   
            url: "https://laviedesidees.fr/Le-genie-ecologique.html",
            quoteList: 
            [
              {
                quote: "Les clefs pour comprendre sont les clefs pour agir.",
              },
              {
                quote: "Chaque citoyen a vocation à devenir sentinnelle du climat.",
              }
            ]
          }
        ]
      }
    ] 
  },
  {
    name: 'Autres',
    list: 
    [
      {
        name: "Podcasts",
        type: "podcast",
        list: 
        [
          {
            title: "De cause à effets, le magazine de l’environnement",
            src: "https://upload.wikimedia.org/wikipedia/fr/thumb/c/c9/France_Culture_-_2008.svg/langfr-180px-France_Culture_-_2008.svg.png",
            name: "France Culture",
            author: "Aurélie Luneau",
            frequency: "Sortie tous les 1er mardis du mois",
            duration: "50 minutes",
            list: [
              {
                src: '<iframe src="https://www.franceculture.fr/player/export-reecouter?content=2a13766f-7702-4f34-8ab7-ffa154b99a99" width="481" frameBorder="0" scrolling="no" height="137"></iframe>'
              },
              {
                src: '<iframe src="https://www.franceculture.fr/player/export-reecouter?content=582770c2-148d-405f-95f7-2dd6dca6177c" width="481" frameBorder="0" scrolling="no" height="137"></iframe>'
              },
              {
                src: '<iframe src="https://www.franceculture.fr/player/export-reecouter?content=38a2c250-0f2d-4594-96d1-58afb3400b11" width="481" frameBorder="0" scrolling="no" height="137"></iframe>'
              }
            ]
          },
          {
            title: "Y’a le feu au lac",
            src: "https://podcasts.ouest-france.fr/attachments/images/channel/1/itunes.png",
            name: "Ouest France",
            author: "Lucas Scaltritti",
            frequency: "Sortie tous les mercredis",
            duration: "25 minutes",
            list: [
              {
                src: "<iframe frameborder=0 width='100%' height='41' style='overflow:hidden;' src='https://podcasts.ouest-france.fr/share/player_mini/video=false&digest=false&auto=false&resp=true&mode=broadcast&id=4892&theme=000000I000000IeeeeeeI353535I353535I818181I777777I000000'>Wikiradio Saooti</iframe>"
              },
              {
                src: "<iframe frameborder=0 width='100%' height='41' style='overflow:hidden;' src='https://podcasts.ouest-france.fr/share/player_mini/video=false&digest=false&auto=false&resp=true&mode=broadcast&id=5027&theme=000000I000000IeeeeeeI353535I353535I818181I777777I000000'>Wikiradio Saooti</iframe>"
              },
              {
                src: "<iframe frameborder=0 width='100%' height='41' style='overflow:hidden;' src='https://podcasts.ouest-france.fr/share/player_mini/video=false&digest=false&auto=false&resp=true&mode=broadcast&id=5174&theme=000000I000000IeeeeeeI353535I353535I818181I777777I000000'>Wikiradio Saooti</iframe>"
              }
            ]
          },
          {
            title: "#2050 Le Podcast",
            src: "https://image.ausha.co/pyjIbzZzuzJq6TfDcYq5WS47A9ksq3wx7DjWJWpy_400x400.jpeg",
            name: "",
            author: " Rebecca Armstrong",
            frequency: "Sortie tous les mercredis",
            duration: "25 minutes",
            list: [
              {
                src: '<iframe frameborder="0" loading="lazy" id="ausha-FPzU" height="220" style="border: none; width:100%; height:220px" src="https://player.ausha.co/index.html?podcastId=ykd4hg9lWqNb&playlist=false&color=%23DD6161&v=3&playerId=ausha-FPzU"></iframe><script src="https://player.ausha.co/ausha-player.js"></script>'
              },
              {
                src: '<iframe frameborder="0" loading="lazy" id="ausha-2jvf" height="220" style="border: none; width:100%; height:220px" src="https://player.ausha.co/index.html?podcastId=bzzmfenjkKZb&playlist=false&color=%23DD6161&v=3&playerId=ausha-2jvf"></iframe><script src="https://player.ausha.co/ausha-player.js"></script>'
              },
              {
                src: '<iframe frameborder="0" loading="lazy" id="ausha-4Lt8" height="220" style="border: none; width:100%; height:220px" src="https://player.ausha.co/index.html?podcastId=oZPxHzwrzWdb&playlist=false&color=%23DD6161&v=3&playerId=ausha-4Lt8"></iframe><script src="https://player.ausha.co/ausha-player.js"></script>'
              }
            ]
          },
          {
            title: "Basilic",
            src: "https://image.ausha.co/8wfdNLJl2llaV77lHdajfcmuE7qjlOIM9a5NLlNn_400x400.jpeg",
            name: "",
            author: " Jeanne Clase",
            frequency: "Sortie tous les mercredis",
            duration: "25 minutes",
            list: [
              {
                src: '<iframe frameborder="0" loading="lazy" id="ausha-5tNj" height="220" style="border: none; width:100%; height:220px" src="https://player.ausha.co/index.html?podcastId=ypzjwU6wExW2&v=3&playerId=ausha-5tNj"></iframe><script src="https://player.ausha.co/ausha-player.js"></script>'
              },
              {
                src: '<iframe frameborder="0" loading="lazy" id="ausha-6SPK" height="220" style="border: none; width:100%; height:220px" src="https://player.ausha.co/index.html?podcastId=odwlvI0dE5el&v=3&playerId=ausha-6SPK"></iframe><script src="https://player.ausha.co/ausha-player.js"></script>'
              },
              {
                src: '<iframe frameborder="0" id="ausha-n5ag" height="220" style="border: none; width:100%" src="https://player.ausha.co/index.html?showId=b2XKgH9G62md&color=%2372238e&podcastId=ompVdI0Aw6e9&v=3&playerId=ausha-n5ag"></iframe><script src="https://player.ausha.co/ausha-player.js"></script>'
              }
            ]
          },
          {
            title: "D'utilité publique",
            src: "https://i1.sndcdn.com/artworks-yAuEziy2V6ZhQAKO-qjoAAA-t500x500.jpg",
            name: "Caisse des Dépots",
            author: "",
            frequency: "Sortie tous les mercredis",
            duration: "25 minutes",
            list: [
              {
                src: '<iframe frameborder="0" loading="lazy" id="ausha-FzYo" height="220" style="border: none; width:100%; height:220px" src="https://player.ausha.co/index.html?podcastId=b28xaTndk17v&playlist=false&color=%2372238e&v=3&playerId=ausha-FzYo"></iframe><script src="https://player.ausha.co/ausha-player.js"></script>'
              },
              {
                src: '<iframe frameborder="0" loading="lazy" id="ausha-hHNa" height="220" style="border: none; width:100%; height:220px" src="https://player.ausha.co/index.html?podcastId=bWYG5tmDxX74&playlist=false&color=%2372238e&v=3&playerId=ausha-hHNa"></iframe><script src="https://player.ausha.co/ausha-player.js"></script>'
              },
              {
                src: '<iframe frameborder="0" loading="lazy" id="ausha-frwO" height="220" style="border: none; width:100%; height:220px" src="https://player.ausha.co/index.html?podcastId=BnQDLUK5NAVd&playlist=false&color=%2372238e&v=3&playerId=ausha-frwO"></iframe><script src="https://player.ausha.co/ausha-player.js"></script>'
              }
            ]
          },
          {
            title: "Présages",
            src: "https://uploads.podcloud.fr/uploads/covers/152d/63d2/9321/c9c7/43fb/7bda/5c46/8f24/d4b2/56bb/medium_152d63d29321c9c743fb7bda5c468f24d4b256bb.jpg?version=1558845789&",
            name: "",
            author: "Alexia Soyeux",
            frequency: "Sortie tous les mois",
            duration: "40-50 minutes",
            list: [
              {
                src: '<iframe width="100%" height="200" src="https://podcloud.fr/podcast/presages/episode/valerie-masson-delmotte-derive-climatique-et-responsabilite/player/fixed-size" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>'
              },
              {
                src: '<iframe width="100%" height="200" src="https://podcloud.fr/podcast/presages/episode/magali-lafourcade-etat-de-droit-etats-durgence/player/fixed-size" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>'
              },
              {
                src: '<iframe width="100%" height="200" src="https://podcloud.fr/podcast/presages/episode/nora-bouazzouni-alimentation-et-stereotypes-de-genre/player/fixed-size" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>'
              }
            ]
          }
        ]
      },
      {
        name: "Formations et cours",
        type: "courses",
        list: 
        [
          {
            type: "Masters en France",
            list: [
              {
                institution: "Sorbonne Université",
                course: "Master Biodiversité, écologie et évolution (BEE)",
                url: "https://sciences.sorbonne-universite.fr/formation-sciences/masters/master-biodiversite-ecologie-et-evolution-bee"
              },
              {
                institution: "Université Paris 1 Panthéon-Sorbonne",
                course: "IMT&DD Innovation, Management des Technologies et Développement Durable",
                url: "https://formations.pantheonsorbonne.fr/fr/catalogue-des-formations/master-M/master-management-de-l-innovation-KBUT1IZM/master-parcours-innovation-management-des-technologies-developpement-durable-imt-dd-_en-apprentissage-KBUT1J6J.html"
              },
              {
                institution: "ESI Geen & Social Business School (Lyon et Paris)",
                course: "Tout programme",
                url: "https://www.esi-business-school.com"
              },
              {
                institution: "Université Paris-Saclay",
                course: "Études du Développement et de l’Environnement",
                url: "https://www.universite-paris-saclay.fr/formation/master/etudes-du-developpement-et-de-lenvironnement"
              },
              {
                institution: "Université Paris 1 Panthéon-Sorbonne",
                course: "M2 Bioterre",
                url: "https://www.environnement-paris1.fr/master2-bioterre"
              },
              {
                institution: "PSIA, Sciences Po Paris",
                course: "Master in Environmental Policy",
                url: "https://www.sciencespo.fr/psia/content/master-environmental-policy.html"
              },
              {
                institution: "Université Paris-Dauphine",
                course: "Développement Durable et Responsabilité des Organisations",
                url: "https://dauphine.psl.eu/formations/masters/affaires-internationales-et-developpement/m2-developpement-durable-et-responsabilites-des-organisations/formation"
              },
              {
                institution: "École de la Gestion et de la Protection de l'Environnement",
                course: "MSc en Environnement",
                url: "https://www.egpn.fr/master-science-en-environnement?gclid=EAIaIQobChMIq9WFyYeF7wIVibrVCh2BdwBLEAAYBCAAEgLBV_D_BwE"
              }
            ]
          },
          {
            type: "Licences en France",
            list: [
              {
                institution: "Université de Rennes",
                course: "Bachelor Environnement et Développement Durable",
                url: "https://www.univ-rennes1.fr/le-developpement-durable-luniversite-de-rennes-1"
              },
              {
                institution: "Institut Supérieur de l’Environnement Versailles",
                course: "Bachelor en Environnement",
                url: "https://institut-superieur-environnement.com"
              }
            ]
          },
          {
            type: "Masters en Europe",
            list: [
              {
                institution: "Erasmus University Rotterdam",
                course: "MSc in Ubran Environment, sustainability and Climate Change: Nature-Based Solutions for Smart and Resilient Cities",
                url: "https://www.eur.nl/en/master/urban-environment-sustainability-and-climate-change"
              },
              {
                institution: "Queen’s University Belfast",
                course: "MSc Ecological Management and Conservation Biology",
                url: "https://www.qub.ac.uk/courses/postgraduate-taught/ecological-management-conservation-biology-msc/"
              },
              {
                institution: "University of Cambridge",
                course: "MSt in Interdisciplinary Design for the Built Environment",
                url: "https://www.ice.cam.ac.uk/course/mst-interdisciplinary-design-built-environment"
              },
              {
                institution: "Instituto Politecnico Milano",
                course: "International Masters in Sustainability Management",
                url: "https://www.som.polimi.it/en/course/international-masters-in-sustainability/?utm_source=MasterStudies&utm_medium=Listing%202020-21&utm_campaign=SUSTAINABILITY%202021"
              },
              {
                institution: "Stockhom University",
                course: "Master of Science in Ecology and Biodiversity",
                url: "https://www.su.se/english/search-courses-and-programmes/nekco-1.411492"
              }
            ]
          },
          {
            type: "Formations continues",
            list: [
              {
                institution: "University of Cambridge",
                course: "Cambridge Institute for Sustainability Leadership",
                url: "https://www.cisl.cam.ac.uk/education/learn-online"
              },
              {
                institution: "Université Virtuelle Environnement et Développement Durable",
                course: "MOOC",
                url: "https://www.uved.fr/#category-89"
              },
              {
                institution: "Future Learn",
                course: "Bushfires: response, relief and resilience",
                url: "https://www.futurelearn.com/courses/bushfires?utm_source=keystone&utm_medium=affiliate&utm_campaign=keystone_courses"
              },
              {
                institution: "Future Learn",
                course: "Teaching Climate Change",
                url: "https://www.futurelearn.com/courses/teaching-climate-change"
              },
              {
                institution: "Formations Bio Sainte Marthe",
                course: "Créer sa Microferme Bio - Entrepreneur Filière Bio",
                url: "http://www.formationsbio.com/agriculture-bio-et-filieres"
              },
              {
                institution: "Les Fermes d'Espoir",
                course: "La Ferme Universitaire P13",
                url: "https://lesfermesdespoir.fr/La-ferme-universitaire-P13-c-est-quoi"
              }
            ]
          }
        ]
      },
      {
        name: "Associations et organisations",
        type: "org"
      }
    ]
  },
];

export class Tabs extends React.Component {
  constructor() {
    super();

    this.state = {
        activeTab: ((window.innerWidth > 680) ? 0 : null),
        activeItem: ((window.innerWidth > 680) ? 0 : null),
        data: data,
        isMobile: (window.innerWidth < 680),
        videoId: null
    }

    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ 
      width: window.innerWidth,
      isMobile: (window.innerWidth < 680)
    });
  }

  componentDidMount() {
    this.updateDimensions()
  }

  changeTabOnClick = ( index ) => {
      this.setState({
          activeTab: (this.state.activeTab === index) ? null : index,
          activeItem: ((window.innerWidth > 680) ? 0 : null),
          videoId: null
      })
  }

  changeItemOnClick = ( index ) => { this.setState({ activeItem: (this.state.activeItem === index) ? null : index, videoId: null })}

  videoActivation = ( index ) => { this.setState({ videoId: index })}

  render() {
    return (
      <div className="content medias">
        {this.state.isMobile ? 
          <TabHeader 
            data={this.state.data}
            changeTab={this.changeTabOnClick}
            changeItem={this.changeItemOnClick}
            videoActivation={this.videoActivation}
            activeTab={this.state.activeTab}
            activeItemId={this.state.activeItem}
            isMobile={this.state.isMobile}
            videoId={this.state.videoId}
          />
        :
        <Fragment>
          <TabHeader 
            data={this.state.data}
            changeTab={this.changeTabOnClick}
            activeTab={this.state.activeTab} 
            isMobile={this.state.isMobile}
          />
          <TabItems 
            data={this.state.data}
            changeItem={this.changeItemOnClick}
            activeTab={this.state.activeTab === null ? 0 : this.state.activeTab}
            activeItemId={this.state.activeItem}
            isMobile={this.state.isMobile}
          />
          <TabContent 
            data={this.state.data}
            videoActivation={this.videoActivation}
            activeTab={this.state.activeTab === null ? 0 : this.state.activeTab}
            activeItemId={this.state.activeItem}
            isMobile={this.state.isMobile}
            videoId={this.state.videoId}
          />
        </Fragment>
          
        }
      </div>
    )
  }
};

class TabHeader extends React.Component {

  changeTab = ( index ) => { this.props.changeTab(index) }

  changeItem = ( index ) => { this.props.changeItem(index) }

  videoActivation = ( index ) => { this.props.videoActivation(index) }

  render () {
    let activeClass = this.props.activeTab;

    let tabs = this.props.data.map((item, index) => {
      return (
        <Fragment key={index}>
          {this.props.isMobile ? 
          <li key={item.name} onClick={() => this.changeTab(index)}>
            <div className="header" >
              <h3>{item.name}</h3>
              <div className={"more " + (activeClass === index ? 'active' : '')}>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className={"content " + (activeClass === index ? 'active' : '')} onClick={(e) => {e.stopPropagation()}}>
              <TabItems 
                data={this.props.data}
                videoActivation={this.videoActivation}
                changeItem={this.changeItem}
                activeTab={index}
                activeItemId={this.props.activeItemId}
                isMobile={this.props.isMobile}
                videoId={this.props.videoId}
              />              
            </div>
          </li>
          :
          <li key={item.name} className={(activeClass === index ? 'active' : '')}>
            <div onClick={() => this.changeTab(index)}><span>{item.name}</span>
            </div>
          </li>
          } 
        </Fragment>  
      )
    });

    return( <ul className="tabs-header">{tabs}</ul> )
  }
};


class TabItems extends React.Component {
  changeItem = ( index ) => { this.props.changeItem(index) }

  videoActivation = ( index ) => { this.props.videoActivation(index) }

  render() {
    let activeClass = this.props.activeTab;
    let activeItemClass = this.props.activeItemId;

    let items = this.props.data[activeClass].list.map((item, index) => {
      return (
        <Fragment key={index}>
          {this.props.isMobile ?
          <li key={item.name} onClick={() => this.changeItem(index)}>
            <div className="header">
              <h3>{item.name}</h3>
              <div className={"more " + (activeItemClass === index ? 'active' : '')}>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className={"content " + (activeItemClass === index ? 'active' : '')} onClick={(e) => {e.stopPropagation()}}>
              <TabContent 
                data={this.props.data}
                videoActivation={this.videoActivation}
                activeTab={this.props.activeTab}
                activeItemId={index}
                isMobile={this.props.isMobile}
                videoId={this.props.videoId}
              />
            </div>
          </li>
          :
          <li key={item.name} className={(activeItemClass === index ? 'active' : '')}>
            <div onClick={() => this.changeItem(index)} >
              <span>
                {item.name}
              </span>
            </div>
          </li>
          }
        </Fragment>
      )
    });

    return( <ul className="tabs-items">{items}</ul> );
  }
};


class TabContent extends React.Component {
  getFlags (languages) {
    let content = languages.map((item, index) => {
      return (
        <div key={item.name} className="flag">
          <img src={flags[item.id]} alt="Drapeau" />
        </div>
      )
    });
    return content
  }

  videoActivation = ( index ) => { this.props.videoActivation(index) }

  getMosaic (list, type, indexItem, activeItemClass) {
    let mosaic = "";
    
    if (type === "interview" || type === "conference" || type === "documentary" || type === "report") {
      mosaic = list.map((item, index) => {
        return (
          <div className="mosaic-item" key={type + index}>
            <div className={type}>
              <div className="video" onClick={() => this.videoActivation(index)} >
                {(this.props.videoId === index && activeItemClass === indexItem) ?
                <iframe title="video" loading="lazy" src={item.url + "?cc_lang_pref=en&amp;cc=1&autoplay=1"} width="100%" height="100%" frameBorder="0" allowFullScreen></iframe>
                :
                <Fragment>
                  <img className="thumbnail" alt="miniature" src={'https://i.ytimg.com/vi/' + item.url.replace("https://www.youtube.com/embed/","") + '/hqdefault.jpg'} />
                  <div className="play"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFFFFF"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd"></path></svg></div>
                </Fragment>
                }
              </div>
              <div className="title">
                {this.getFlags(item.languages)}
                <h4>{item.title}</h4>
              </div>
            </div>
          </div>
        )
      });
    };
    if (type === "book") {
      mosaic = list.map((item, index) => {
        return (
          <div className="mosaic-item" key={type + index}>
              <div className={type}>
                  <div className="cover">
                    <img src={item.src} alt="book cover"/>
                  </div>
                  <div className="content">
                    <div className="title">
                      <h4>{item.title}</h4>
                    </div>
                    <div className="author">
                      <h5>{item.author}</h5>
                    </div>
                    <div className="quote">
                      {item.quoteList.map((item, index) => {return (
                        <p key={index}>{item.quote ? <span>"{item.quote}"</span> : null} {item.page ? <span> page {item.page}</span> : null}</p>
                      )})}
                    </div>
                  </div>
              </div>
          </div>
        )
      });
    };
    if (type === "article") {
      mosaic = list.map((item, index) => {
        return (
          <div className="mosaic-item" key={type + index}>
            
              <div className={type}>
                <a href={item.url} target="_blank" rel="noreferrer">
                  <div className="cover">
                    <img src={item.img} alt="article cover"/>
                  </div>
                  <div className="content">
                    <div className="title">
                      <h4>{item.title}</h4>
                    </div>
                    <div className="attributes">
                      <h5 className="info"><span className="newspaper">{item.newspaper}</span><span className="author">{item.author}</span></h5>
                      <h5 className="date">{item.date}</h5>
                    </div>
                    {/* <div className="quote">
                      {item.quoteList.map((item, index) => {return (
                        <p>“{item.quote}”</p>
                      )})}
                    </div> */}
                  </div>
                </a>
              </div>
          </div>
        )
      });
    };
    if (type === "podcast") {
      mosaic = list.map((item, index) => {
        return (
          <div className="mosaic-item" key={type + index}>
            <Podcast 
              type={type}
              title={item.title}
              src={item.src}
              name={item.name}
              author={item.author}
              frequency={item.frequency}
              duration={item.duration}
              list={item.list}
            />
          </div>
        )
      });
    };
    if (type === "courses") {
      mosaic = list.map((item, index) => {
        return (
          <div className="mosaic-item" key={type + index}>
              <div className={type}>
                <div className="title"><h3>{item.type}</h3></div>
                <div className="content">
                  {item.list.map((item, index) => {return (
                    <a key={index} href={item.url} target="_blank" rel="noreferrer">
                      <div className="institution"><h4>{item.institution}</h4></div>
                      <div className="course"><p>{item.course}</p></div>
                    </a>
                  )})}
                </div>
              </div>
          </div>
        )
      });
    };
    if (type === "org") {
      return (
        <div className="mosaic-item">
          <div className={type}>
            <p>Vous pouvez retrouver la liste des associations et organisations sur la page :</p>
            <Link to="/action"><button type="button">Agir</button></Link>
          </div>
        </div>
      )
    };
    return (mosaic)
  }

  render() {
    let activeClass = this.props.activeTab;
    let activeItemClass = this.props.activeItemId;

    let content = this.props.data[activeClass].list.map((item, index) => {
      return (
        <div key={item.name} className={'tabs-mosaic ' + (activeItemClass === index ? 'show' : '') + ' tabs-mosaic-' + item.type} >
          {this.getMosaic(item.list, item.type, index, activeItemClass)}
        </div>
      )
    });

    return( <div className="tabs-content">{content}</div> );
  }
}


class Podcast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: '0',
      display : 'none'
    };
    
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
  }

  toggle() {
    const newOpacity = '1'; //'1' ? '0' : '1';
    const newDisplay = 'block';
    this.setState({
      opacity: newOpacity,
      display: newDisplay
    });
  }

  close() {
    const newOpacity = '0';
    const newDisplay = 'none';
    this.setState({
      opacity: newOpacity,
      display: newDisplay
    });
  }

  render() {

    // let content = this.props.list.map((item, index) => {
    //   return (
    //     <div className="episode" key={index}>
    //       {/* {parse(item.src)} */}
    //     </div>
    //   )
    // });

    return(
      <div className={this.props.type} key={this.props.name}>
        <div className="card" onClick={this.toggle}>
          <div className="title">
            <h4>{this.props.title}</h4>
          </div>
          <div className="content">
            <div className="logo">
              <img src={this.props.src} alt="logo"/>
            </div>
            <div className="information"> 
              <h5><span className="name">{this.props.name}</span></h5>
              <h5 className="author">{this.props.author}</h5>
              <h5>{this.props.frequency}</h5>
              <h5>{this.props.duration}</h5>
            </div> 
          </div>
        </div>
        {/* <div className="description" style={{ opacity: this.state.opacity, display: this.state.display }}>
          <div className="header">
            <h3>{this.props.title}</h3>
            <div className="close" onClick={this.close}>
              <img src={close} alt="close icon" onClick={this.close}/>
            </div>
          </div>
          <div className="content">
            {content}
          </div>
          <div className="link">
            <a href={this.props.url}>Lien vers le site</a>
          </div>
        </div> */}
      </div>
    );
  }
}
