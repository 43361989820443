import React, { useContext } from 'react';

import { languageOptions } from '../../languages';
import { LanguageContext } from '../../containers/LanguageContext';

import {FR} from "../../languages/svgFlags.js";
import {EN} from "../../languages/svgFlags.js";
import {ES} from "../../languages/svgFlags.js";
import {PO} from "../../languages/svgFlags.js";

import "./LanguageSelector.css";


const flags = [<FR/>, <EN/>, <ES/>, <PO/>];

export default function LanguageSelector() {
  const { userLanguageChange } = useContext(LanguageContext);

  // set selected language by calling context method
  const handleLanguageChange = e => userLanguageChange(e.target.value);

  return (
    <div className="flagsGrid">
      {Object.entries(languageOptions).map(([id, name], index) => (
        <button onClick={handleLanguageChange} key={id} value={id}>
          {flags[index]}
        </button>
      ))}
    </div>
    
  );
};

/* <select
      onChange={handleLanguageChange}
      value={userLanguage}
    >
      {Object.entries(languageOptions).map(([id, name]) => (
        <option key={id} value={id}>{name}</option>
      ))}
    </select>

<div className="line">
<img onClick={handleLanguageChange} key="fr" value="fr" src={ frFlag } alt="Drapeau FR"/>
<img onClick={handleLanguageChange} key="en" value="en" src={ enFlag } alt="Drapeau EN"/>
</div>
<div className="line">
<div onClick={handleLanguageChange}><img src={ esFlag } alt="Drapeau ES"/></div>
<div onClick={handleLanguageChange}><img src={ poFlag } alt="Drapeau PO"/></div>
</div> */