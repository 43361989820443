import React from "react";


import { Text } from '../../containers/Language';


import "./Projects.css";


export class Projects extends React.Component {
  render() {
    return (
      <div className="content projects">
        <div className="item">
          <h3>{this.props.bodyIntroductionText}</h3>
          <p>{<Text tid="association.projects.project1" />}</p>
          <p>{<Text tid="association.projects.project2" />}</p>
        </div>
      </div>
    )
  }
}
