import React, { Component } from "react";
import { Switch } from "react-router-dom";
import { Route } from 'react-router'
import { About } from "./pages/About.js"
import { Home } from "./pages/Home.js"
import { Education } from "./pages/Education.js"
import { Action } from "./pages/Action.js"
import { Mobilisation } from "./pages/Mobilisation.js"
import { Contact } from "./pages/Contact.js"
import { LanguageProvider } from "./containers/Language.js"
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import "./App.css";
import "./Normalize.css";


class App extends Component {
  render() {
    return (
      <LanguageProvider>
        <div className="pen">
          <div className="pen-content">
            <ScrollToTop>
              <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/association" component={About} />
                  <Route exact path="/education" component={Education} />
                  <Route exact path="/action" component={Action} />
                  <Route exact path="/mobilisation" component={Mobilisation} />
                  <Route exact path="/contact" component={Contact} />
              </Switch>
           </ScrollToTop>
          </div>
        </div>
      </LanguageProvider>
    );
  }
}

export default App;
    